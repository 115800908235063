// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import components
import {LinkButton} from "../../components/buttons/buttons";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {Switch} from "antd";
import PageWrapper from "../../components/pageContentViews/pageWrapper";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {UpdateLanguage} from "../../redux/actions";
import {getInputValues, toggleStateField} from "../../utils/helperFunctions";
import {MediaFileCard} from "../../components/media/MediaFileCard";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";

class EditLanguage extends Component {
    constructor() {
        super();

        this.state = {
            fieldsData: {
                isHidden: false,
                iconPath: undefined,
                name: "",
                shortName: "",
            },
            isEditing: true,
            errorName: false,
            errorLogo: false,
            errorShortName: false,
            mediaModalOpen: false,
        };

        this.currentData = null;
        this.updatedDataMap = new Map();
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.getInputValues = getInputValues.bind(this);
        this.editLanguage = this.editLanguage.bind(this);
        this.getMedia = this.getMedia.bind(this);
    }

    componentDidMount() {
        const {languages, id} = this.props;
        const lgData = languages && id && languages.find(lg => lg.id === id);
        this.currentData = lgData ? {...lgData} : {};

        lgData && this.setState({
            fieldsData: {
                name: lgData.name,
                iconPath: lgData.iconPath,
                shortName: lgData.shortName,
                isHidden: lgData.isHidden,
            },
        });

    }

    getMedia(mediaArray) {
        const {fieldsData} = this.state;
        this.currentData?.iconPath?.id !== mediaArray[0]?.id
            ? this.updatedDataMap.set('iconPath', mediaArray[0]?.id)
            : this.updatedDataMap.delete('iconPath');
        this.setState({
            fieldsData: {
                ...fieldsData,
                iconPath: mediaArray[0],
            },
            errorLogo: false,
        })
    }


    editLanguage() {
        const {fieldsData} = this.state;
        const {name, shortName, iconPath, isHidden} = fieldsData;
        const id = this.props.id;

        if (!name ) {
            this.setState({
                errorName: !name,
                errorShortName: !shortName,
            });
            return;
        }
        const reqData = {
            name,
            iconPath:iconPath?.id,
            isHidden
        }
        this.props.UpdateLanguage(reqData, id)

    }


    render() {
        const {requestLoading} = this.props;
        const {fieldsData, errorName,errorShortName, errorLogo, mediaModalOpen} = this.state;


        return <PageWrapper pageTitle={'Փոփոխել Լեզուն'}>
            <section className="general-add-edit">
                {!this.currentData?.isMain && <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden} name="isHidden"
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>}
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errorLogo ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.iconPath && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.iconPath}/>
                </div>}
                <InputGroup value={fieldsData.name}
                            initValue={this.currentData?.name}
                            label={"Անուն"}
                            inputType={"input"}
                            type={'text'}
                            name={"name"}
                            maxLength={20}
                            error={errorName}
                            required={true}
                            onChange={this.getInputValues}/>

                {/*<InputGroup value={fieldsData.shortName}
                            initValue={this.currentData?.shortName}
                            label={"Կարճ Անուն"}
                            inputType={"input"}
                            type={'text'}
                            name={"shortName"}
                            maxLength={20}
                            error={errorShortName}
                            required={true}
                            onChange={this.getInputValues}/>*/}

                <div className="flex-wrapper-right">
                    <LinkButton cb={this.editLanguage}
                                disabled={!this.updatedDataMap.size}
                                loading={requestLoading}
                                title="Փոփոխել"/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    UpdateLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLanguage);
